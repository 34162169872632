.content {
  width: 270px;
  min-height: 150px;
  background: white;
  border-radius: 0.5rem;
  position: absolute;
  left: 50%;
  translate: -50%;
  transform-origin: 50% 50% -450px;
  outline: 1px solid transparent;
  padding: 0.5rem 0.7rem;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.31);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.31);
}

.content .date {
  font-size: 0.8rem;
}

.content .name {
  font-size: 1rem;
}

.content .rating {
  color: lightskyblue;
  font-size: 0.8rem;
}

.content .product {
  color: green;
}

.content .review {
  font-size: 0.9rem;
}

.slider {
  perspective: 1200px;
  padding-top: 10%;
  position: absolute;
  top: 40%;
  left: 50%;
}

.spinner {
  transform-style: preserve-3d;
  height: 300px;
  transform-origin: 50% 50% -450px;
  transition: 1s;
  animation: rotation 25s infinite linear;
  transform: rotateY(0);
}

/* @keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
} */

.content-1 {
  transform: rotateY(0deg);
}
.content-2 {
  transform: rotateY(-45deg);
}
.content-3 {
  transform: rotateY(-90deg);
}
.content-4 {
  transform: rotateY(-135deg);
}
.content-5 {
  transform: rotateY(-180deg);
}
.content-6 {
  transform: rotateY(-225deg);
}
.content-7 {
  transform: rotateY(-270deg);
}
.content-8 {
  transform: rotateY(-315deg);
}

@media all and (min-width: 380px) {
  .content .date {
    font-size: 0.9rem;
  }

  .content .name {
    font-size: 1.1rem;
  }

  .content .rating {
    color: lightskyblue;
    font-size: 0.9rem;
  }

  .content .product {
    color: green;
  }

  .content .review {
    font-size: 1rem;
  }
}

@media all and (min-width: 480px) {
  .content .date {
    font-size: 1rem;
  }

  .content .name {
    font-size: 1.2rem;
  }

  .content .rating {
    color: lightskyblue;
    font-size: 1rem;
  }

  .content .product {
    color: green;
  }
}
