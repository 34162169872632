.navbar-container {
  width: 100vw;
  height: 90px;

  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  height: 100%;
}

.logo {
  width: 200px;
}

.navbar-right {
  text-align: center;
}

.navbar-login {
  display: flex;
  justify-content: center;
}

.login-image-container {
  width: 40px;
  height: 20px;
  background: black;
  border-radius: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 0.2rem;
}

.number {
  margin-bottom: 0.2rem;
}
