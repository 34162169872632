.section-two-desk {
  width: 100vw;
  min-height: 100vh;
  position: relative;
}

.section-two-desk-img {
  width: 100vw;
  min-height: 100%;
  bottom: 0;
  object-fit: cover;
  display: block;
  position: absolute;
}

.section-two-desk h1 {
  font-size: 2.5rem;
  padding-top: 12vh;
  text-align: center;
  position: relative;
  margin-bottom: 2rem;
}

.section-two-desk-details {
  position: relative;
  width: 30%;
  line-height: 1.5;
  font-size: 1.6rem;
  margin-left: 2rem;
}

.section-two-desk-doc-icon-container {
  display: flex;
}

.section-two-desk-doc-icon-container img {
  width: 35%;
}

.section-two-desk-logo-container {
  display: flex;
}

.section-two-desk-logo-container img {
  width: 40%;
  object-fit: contain;
}

.section-two-desk-logo-container img:nth-child(1) {
  width: 30%;
}
