.header-desk {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.header-desk-img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: 60% 50%;
  opacity: 0.8;
  display: block;
}

.header-desk-top {
  position: absolute;
  top: 12vh;
  left: 10%;
  text-align: center;
  width: 30%;
}

.header-desk-top p {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.header-desk-odometer {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.header-desk-bottom {
  position: absolute;
  bottom: 2%;
  width: 100%;
  text-align: center;
}

.header-desk-bottom h2 {
  font-size: 1.2rem;
}

.header-desk-right {
  position: absolute;
  right: 2%;
  bottom: -7%;
  width: 30%;
}

.header-desk-right img {
  width: 100%;
  object-fit: contain;
}

.header-desk-video {
  margin-top: 3rem;
}

.header-desk-video iframe {
  width: 300px;
  height: 200px;
}

@media all and (min-width: 1000px) {
  .header-desk-video {
    margin-top: 5rem;
  }
  .header-desk-video iframe {
    width: 400px;
    height: 300px;
  }
}
