.navbar-desk-container {
  width: 100vw;
  height: 10vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

.navbar-desk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  height: 100%;
}

.navbar-desk-logo {
  width: 200px;
}

.navbar-desk-right {
  text-align: center;
}

.navbar-desk-login {
  display: flex;
  justify-content: center;
}

.navbar-desk-login-image-container {
  width: 40px;
  height: 20px;
  background: black;
  border-radius: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 0.2rem;
}

.navbar-desk-number {
  margin-bottom: 0.2rem;
}
