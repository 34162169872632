.section-8a {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background: white;
  z-index: 55;
  overflow: hidden;
}

.section-8a-top {
  padding-top: 100px;
  margin-left: 0.5rem;
}

.section-8a-top h1 {
  font-size: 1.7rem;
}

.section-8a-top p {
  width: 35%;
  line-height: 1.3;
  margin-top: 1rem;
  font-weight: 500;
}

.scientist-img {
  position: absolute;
  width: 100vw;
  min-height: calc(100vh - 90px);
  object-fit: cover;
  bottom: 0;
  right: 0;
  object-position: 60% 50%;
  opacity: 0.7;
  z-index: -1;
}

.section-8a .icon-container {
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-top: 1rem;
}

.section-8a .icon-container img {
  width: 90%;
}

.section-8a .icon-container img:nth-child(2) {
  margin-left: -1rem;
}

.section-8a .logo-container {
  display: flex;
  align-items: center;
}

.section-8a .logo-container img:nth-child(1) {
  width: 20%;
}

.section-8a .logo-container img:nth-child(2) {
  width: 40%;
  height: 40%;
}

.bottom-doc-container {
  position: relative;
  padding-bottom: 70px;
}

.scientist-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 1%;
  width: 100%;
}

@media all and (min-width: 380px) {
  .section-8a-top h1 {
    font-size: 2rem;
  }
  .section-8a-top p {
    width: 40%;
    line-height: 1.3;
    font-size: 1.1rem;
  }
  .scientist-bottom h2 {
    font-size: 1.8rem;
  }
}

@media all and (min-width: 480px) {
  .section-8a-top h1 {
    font-size: 2.2rem;
  }
  .section-8a-top p {
    width: 40%;
    line-height: 1.3;
    font-size: 1.2rem;
  }
  .scientist-bottom h2 {
    font-size: 2.2rem;
  }
}
