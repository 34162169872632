.third-section {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background: white;
  z-index: 50;
}

.third-section .smilling-img {
  width: 100vw;
  min-height: 100vh;
  object-fit: cover;
  object-position: 85% 50%;
  opacity: 0.5;
  display: block;
}

.third-section .title {
  position: absolute;
  left: 2%;
  top: 11%;
}

.third-section .title h1 {
  font-size: 1.7rem;
  padding-top: 0.5rem;
  visibility: hidden;
}

.third-section .title p {
  font-size: 1.2rem;
}

.third-section-bottom {
  position: absolute;
  bottom: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.8rem;
}

.third-section-bottom h1 {
  font-size: 1.3rem;
  z-index: 56;
}

.rounded-carousel {
  position: absolute;
}

@media all and (min-width: 380px) {
  .third-section .title h1 {
    font-size: 2rem;
  }
  .third-section .title p {
    font-size: 1.5rem;
  }
  .third-section-bottom h1 {
    font-size: 1.5rem;
  }
}

@media all and (min-width: 480px) {
  .third-section .title h1 {
    font-size: 2rem;
  }
  .third-section .title p {
    font-size: 1.7rem;
  }
  .third-section-bottom h1 {
    font-size: 1.8rem;
  }
}
