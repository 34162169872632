.first-section {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background: white;
  z-index: 11;
}

.first-section-img {
  width: 500px;
  object-fit: cover;
  display: block;
  padding-top: 100px;
}

.first-section-bottom {
  position: absolute;
  left: 0;
  bottom: 2%;
  margin-left: 0.5rem;
  width: 100%;
}

.first-section-bottom p {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.first-section-bottom .bottom-title {
  font-size: 2rem;
}

.first-section-bottom .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.first-section-bottom .bottom h1 {
  font-size: 1.5rem;
}

@media all and (min-width: 380px) {
  .first-section-bottom .bottom-title {
    font-size: 2.2rem;
  }
  .first-section-bottom p {
    font-size: 1.2rem;
  }
  .first-section-bottom .bottom h1 {
    font-size: 1.7rem;
  }
}

@media all and (min-width: 480px) {
  .first-section-bottom .bottom-title {
    font-size: 2.5rem;
  }
  .first-section-bottom p {
    font-size: 1.3rem;
  }
  .first-section-bottom .bottom h1 {
    font-size: 2rem;
  }
}
