.footer {
  width: 100vw;
  z-index: 55;
  background: black;
  position: relative;
}

.footer-detail-container {
  height: 100%;
  color: white;
  padding: 0 0.2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-detail-container:first-child {
  padding-top: 1rem;
}

.footer-detail-container:last-child {
  padding-bottom: 1rem;
}

.footer-detail-container p:nth-child(1) {
  padding-bottom: 0.5rem;
}

.footer-detail-container p:nth-child(2) {
  padding-bottom: 0.5rem;
}
