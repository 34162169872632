.mouse-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mouse-icon {
  font-size: 2.5rem;
}

.mouse-arrow {
  font-size: 1.4rem;
  animation: anim 1s infinite linear;
}

@keyframes anim {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}

@media all and (min-width: 600px) {
  .mouse-icon-container {
    display: none;
  }
}
