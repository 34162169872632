.section-7a {
  width: 100vw;
  height: 100vh;
  z-index: 55;
  background: white;
  position: relative;
  overflow: hidden;
}

.yellow-woman {
  width: 100vw;
  min-height: 70vh;
  object-fit: cover;
  right: -20%;
  opacity: 0.4;
  position: absolute;
  bottom: 0;
}

.section-7a-swiper {
  position: relative;
}

.section-7a-top {
  padding-top: 100px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 260px;
}

.section-7a-top h1 {
  font-size: 1.4rem;
}

.section-7a-top p {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  position: relative;
  z-index: 50;
}

.brush-carousel {
  position: relative;
  margin-left: 2rem;
  margin-top: 3rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.brush-bottom {
  width: 8vh;
}

.brush-gif {
  width: 30vh;
  margin-bottom: -1.5rem;
  animation: bounce 1s infinite linear;
}

.section-7a-swiper-slide {
  height: 70vh;
  margin-top: 2rem;
  background: white;
  position: relative;
}

.second-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 100%;
}

.second-slide p {
  width: 80%;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
}

.section-7a-brush {
  width: 7vh;
}

.third-slide {
  position: relative;
  height: 100%;
}

.brush-battery-container {
  width: 30%;
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.battery {
  position: absolute;
  width: 800px;
}

.section-7a-third-slide-img {
  width: 100%;
  margin-top: -12rem;
}

.backpack-woman {
  width: 100vw;
  min-height: 70vh;
  object-fit: cover;
  z-index: -1;
  margin-top: 2rem;
  top: 0;
  opacity: 0.4;
  position: absolute;
  object-position: 100% 50%;
}

@keyframes bounce {
  0% {
    top: -63%;
  }
  50% {
    top: -66%;
  }
  100% {
    top: -63%;
  }
}

@media all and (min-width: 380px) {
  .second-slide p {
    font-size: 1rem;
  }
}
