.header {
  width: 100vw;
  min-height: 100vh;
  position: relative;
}

.header-img {
  width: 100%;
  min-height: 100vh;
  object-position: 45% 50%;
  object-fit: cover;
  display: block;
  z-index: -2;
}

.header-video {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
}

.header-video iframe {
  width: 70vw;
  height: 15vh;
}

.header-logo-container {
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  width: 250px;
  height: 200px;
  right: 50px;
  bottom: 130px;
  border-radius: 0.5rem;
  outline: 5px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f0401f;
  z-index: 1;
}

.header-details-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 100px;
}

.header-details-top h1 {
  font-size: 1.2rem;
}

.header-details-top p {
  padding-top: 0.5rem;
  text-align: center;
  font-size: 1.2rem;
}

.header-details-bottom {
  position: absolute;
  bottom: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 0.5rem;
}

.header-details-bottom h1 {
  font-size: 1.7rem;
  font-weight: 500;
  z-index: 12;
}

@media all and (min-width: 380px) {
  .header-details-top h1 {
    font-size: 1.5rem;
  }
  .header-details-top p {
    font-size: 1.5rem;
  }
  .header-video iframe {
    width: 60vw;
    height: 15vh;
  }
  .header-details-bottom h1 {
    font-size: 2rem;
  }
}

@media all and (min-width: 480px) {
  .header-details-top h1 {
    font-size: 1.7rem;
  }
  .header-details-top p {
    font-size: 1.7rem;
  }
  .header-video iframe {
    width: 55vw;
    height: 20vh;
  }
  .header-details-bottom h1 {
    font-size: 2.5rem;
  }
}

@media all and (min-width: 600px) {
  .header-video iframe {
    width: 400px;
    height: 300px;
  }
}
