.section-9a {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background: white;
  z-index: 55;
  overflow: hidden;
}

.section-9a-top {
  position: relative;
  padding-top: 120px;
  margin-left: 0.5rem;
  z-index: 60;
}

.section-9a-top p {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.shower-img {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 90px);
  object-fit: cover;
  bottom: 0;
  opacity: 0.6;
}

.water-brush-img {
  position: absolute;
  left: -80%;
  width: 180%;
  bottom: 0;
}
