.section-4a {
  width: 100vw;
  min-height: 2500px;
  position: relative;
  background: white;
  z-index: 50;
}

.section-4a-top {
  margin: 0 0.5rem;
}

.section-4a-top p {
  font-size: 1rem;
}

.section-4a-title {
  margin-bottom: 0.5rem;
  padding-top: 100px;
  font-size: 1.7rem;
}

.spedometer {
  position: absolute;
  right: 10%;
  z-index: 60;
}

.spedometer div {
  background: black;
  position: relative;
  width: 150px;
  height: 100px;
  margin-top: 1rem;
}

.spedometer img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10%;
}

.spedometer .img-2 {
  transform-origin: 70px 75px;
  rotate: 25deg;
}

.spedometer h4 {
  color: white;
  position: absolute;
  bottom: 10%;
  font-size: 0.8rem;
  text-align: center;
  width: 100%;
  opacity: 0;
}

.brush-video {
  width: 150px;
  margin-top: 120px;
  rotate: 270deg;
  position: absolute;
  top: -5%;
}

.drop-video {
  width: 100vw;
  height: 65vh;
  object-fit: cover;
  z-index: -1;
  object-position: 30% 50%;
}

@media all and (min-width: 380px) {
  .section-4a-title {
    font-size: 2rem;
  }
  .section-4a-top p {
    font-size: 1.2rem;
  }
}

@media all and (min-width: 480px) {
  .section-4a-title {
    font-size: 2.4rem;
  }
  .section-4a-top p {
    font-size: 1.3rem;
  }
}
