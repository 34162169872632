.header-one {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.header-one-img {
  width: 100vw;
  min-height: 60vh;
  object-position: 40% 50%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  opacity: 0.8;
}

.header-one-top {
  position: absolute;
  margin-top: 110px;
  margin-left: 1rem;
  font-weight: 700;
}

.header-one-top h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

.header-one-top p {
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  margin-top: 1rem;
}

.header-odometer {
  font-size: 1.5rem;
  margin-left: 1rem;
}

.header-counters {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.anim-img-container {
  position: absolute;
  bottom: 10%;
  right: 10%;
  z-index: 10;
  width: 6vh;
}

.anim-img {
  width: 100%;
}

.header-one-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 1rem;
}

.header-one-bottom h1 {
  font-size: 1rem;
  backdrop-filter: blur(20px);
  padding: 0.5rem;
}

@media all and (min-width: 380px) {
  .header-one-img {
    object-position: 50% 50%;
  }
  .header-one-top h1 {
    font-size: 2rem;
  }
  .header-odometer {
    font-size: 2rem;
  }
  .header-one-top p {
    font-size: 1.5rem;
  }
  .header-one-bottom h1 {
    font-size: 1.2rem;
  }
}

@media all and (min-width: 480px) {
  .header-one-img {
    object-position: 70% 50%;
  }
  .header-one-top h1 {
    font-size: 2.5rem;
  }
  .header-one-bottom h1 {
    font-size: 1.5rem;
  }
}

@media all and (min-width: 600px) {
  .header-one-top h1 {
    font-size: 3rem;
  }
  .header-one-bottom h1 {
    font-size: 2rem;
  }
  .header-odometer {
    font-size: 2.5rem;
  }
  .header-one-top p {
    font-size: 1.7rem;
  }
}

@media all and (min-width: 700px) {
  .header-one-top h1 {
    font-size: 3.2rem;
  }
  .header-one-bottom h1 {
    font-size: 2.2rem;
  }
  .header-odometer {
    font-size: 2.7rem;
  }
  .header-one-top p {
    font-size: 2rem;
  }
}
