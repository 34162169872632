.second-section {
  width: 100vw;
  min-height: 50vh;
  position: relative;
  background: white;
  z-index: 55;
}

.second-section-img {
  width: 100vw;
  min-height: 60vh;
  object-fit: cover;
  position: relative;
  opacity: 0.7;
  display: block;
  padding-top: 100px;
}

.main-brush-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.main-brush-container .main-brush {
  margin-top: -14rem;
  width: 4vh;
}

.main-brush-container .main-brush-detail {
  background: white;
  width: 100%;
  min-height: 30px;
  position: absolute;
  font-size: 0.7rem;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.36);
  -webkit-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.36);
}

.second-section .title {
  position: absolute;
  left: 2%;
  top: 12%;
  font-size: 1.5rem;
}

.second-section .sub-title {
  position: absolute;
  top: 25%;
  right: 10%;
  text-align: center;
}

.second-section .sub-title p {
  font-size: 1.5rem;
}

.second-section-bottom {
  width: 100%;
  position: relative;
  margin: 0 0.5rem;
  padding-bottom: 1rem;
  bottom: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.second-section-bottom h1 {
  font-size: 1.3rem;
  z-index: 56;
}

@media all and (min-width: 380px) {
  .second-section .title {
    font-size: 1.7rem;
  }
  .second-section .sub-title p {
    font-size: 1.7rem;
  }
  .main-brush-container .main-brush-detail {
    font-size: 0.9rem;
  }
  .second-section-bottom h1 {
    font-size: 1.6rem;
  }
}

@media all and (min-width: 480px) {
  .second-section .title {
    font-size: 2rem;
  }
  .second-section .sub-title p {
    font-size: 2rem;
  }
  .main-brush-container .main-brush-detail {
    font-size: 1rem;
  }
  .second-section-bottom h1 {
    font-size: 2rem;
  }
}
