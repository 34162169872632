.section-one-desk {
  width: 100vw;
  min-height: 100vh;
  position: relative;
}

.section-one-desk-img {
  width: 100vw;
  min-height: 100vh;
  object-fit: cover;
  display: block;
  opacity: 0.7;
}

.section-one-desk-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  right: 10%;
}

.section-one-desk-detail-container div:nth-child(2) {
  margin-left: 12rem;
}

.section-one-desk-single-detail {
  width: 350px;
  min-height: 100px;
  margin-bottom: 2rem;
  padding: 1rem;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-one-desk-detail-container h2,
.section-one-desk-detail-container p {
  display: inline;
}

.section-one-desk-detail-container h2 {
  font-size: 1.7rem;
}

.section-one-desk-detail-container p {
  font-size: 1.2rem;
}
