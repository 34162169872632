* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
}

.main-desktop-view {
  overflow-x: hidden;
}

@media all and (min-width: 600px) {
  .main-mobile-view {
    display: none;
  }
  .main-desktop-view {
    display: block;
  }
}

@media all and (max-width: 600px) {
  .main-mobile-view {
    display: block;
  }
  .main-desktop-view {
    display: none;
  }
}
