.section-10a {
  width: 100vw;
  min-height: 60vh;
  position: relative;
  background: white;
  z-index: 55;
  overflow: hidden;
}

.section-10a-top {
  padding-top: 100px;
  margin-left: 0.5rem;
  z-index: 60;
  font-size: 1.7rem;
}

.brush-package-img {
  width: 100vw;
  position: relative;
  z-index: -1;
  margin-top: -4rem;
}

.brush-heads-section {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brush-heads-section div:nth-child(1) {
  width: 50%;
  text-align: center;
  margin-left: 0.5rem;
}

.brush-heads-section div:nth-child(1) p {
  font-weight: 700;
}

.brush-heads-section div:nth-child(2) {
  margin-left: 2rem;
  flex: 1;
  text-align: center;
}

.brush-heads-section div:nth-child(2) h1 {
  font-size: 1.5rem;
}

.brush-heads-img {
  width: 100%;
}

.dates div:nth-child(2) {
  margin-bottom: 0.5rem;
}

.dates h2 {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 1rem;
}

.dates div {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

@media all and (min-width: 380px) {
  .section-10a-top {
    font-size: 2rem;
  }
}

@media all and (min-width: 480px) {
  .section-10a-top {
    font-size: 2.2rem;
  }
  .dates h2 {
    font-size: 1.2rem;
  }
  .dates div {
    font-size: 1.4rem;
  }
}
