.carousel {
  width: 100%;
  height: 30%;
}

.carousel img {
  width: 35px;
  height: 100%;
  object-fit: contain;
  background: white;
}

.single-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.single-slide-detail {
  min-width: 150px;
  min-height: 20px;
  text-align: center;
  background: white;
  position: absolute;
  padding: 0.2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.36);
  -webkit-box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.36);
}

.single-slide-detail-title {
  text-decoration: underline;
  font-size: 0.7rem !important;
}

.single-slide-detail div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0.2rem;
}

.single-slide-detail p {
  font-size: 0.8rem;
  font-weight: 700;
}

@media all and (min-width: 380px) {
  .single-slide-detail-title {
    font-size: 0.9rem !important;
  }
  .single-slide-detail p {
    font-size: 0.9rem;
  }
}

@media all and (min-width: 480px) {
  .single-slide-detail-title {
    font-size: 1rem !important;
  }
  .single-slide-detail {
    padding: 0.3rem;
  }
  .single-slide-detail p {
    font-size: 1rem;
  }
}
