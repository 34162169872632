.section-12a {
  width: 100vw;
  min-height: 60vh;
  position: relative;
  background: white;
  z-index: 55;
}

.section-12a-top {
  padding-top: 100px;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-12a-top h1 {
  font-size: 1.3rem;
}

.section-12a-top-para {
  width: 70%;
  margin: 1rem auto;
  line-height: 1.3;
  font-size: 1.1rem;
}

.section-12a-top-para span {
  transition: all 0.5s ease-in-out;
}

.para-two-container,
.para-one-container {
  position: relative;
}

.para-two-container h1 {
  font-size: 1.7rem;
  transition: all 0.5s ease-in-out;
}

.section-12a-middle {
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  height: 110px;
  margin-top: 0.5rem;
}

.middle-arrow-up {
  position: absolute;
  font-size: 2.5rem;
  right: 50%;
  top: 0;
  z-index: 5;
  cursor: pointer;
}

.middle-arrow-down {
  position: absolute;
  font-size: 2.5rem;
  right: 50%;
  bottom: 15%;
  z-index: 5;
  cursor: pointer;
}

.middle-arrow-right {
  position: absolute;
  font-size: 2.5rem;
  right: 2%;
  top: 50%;
  z-index: 5;
  cursor: pointer;
}

.middle-arrow-left {
  position: absolute;
  font-size: 2.5rem;
  z-index: 5;
  top: 50%;
  left: 2%;
  cursor: pointer;
  transform: translateX(40%);
}

.section-12a-middle img {
  width: 25%;
  margin-right: 1rem;
  object-fit: contain;
}

.section-12a-middle p {
  line-height: 1.3;
  width: 100%;
  font-size: 0.9rem;
}

.above-section-12a-bottom {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}

.above-section-12a-bottom h2 {
  font-size: 1rem;
}

.above-section-12a-bottom img {
  width: 40%;
  object-fit: contain;
}

.section-12a-bottom {
  display: flex;
  margin-top: 1rem;
}

.section-12a-bottom img {
  object-fit: cover;
}

.section-12a-bottom img:nth-child(1) {
  width: 50%;
  height: 20vh;
}

.section-12a-bottom img:nth-child(2) {
  width: 50%;
  height: 20vh;
}

@media all and (min-width: 380px) {
  .section-12a-bottom img:nth-child(1) {
    height: 25vh;
  }
  .section-12a-bottom img:nth-child(2) {
    height: 25vh;
  }
}

@media all and (min-width: 480px) {
  .section-12a-top h1 {
    font-size: 1.5rem;
  }
  .section-12a-middle p {
    font-size: 1.2rem;
  }
  .above-section-12a-bottom h2 {
    font-size: 1.2rem;
  }
  .section-12a-bottom img:nth-child(1) {
    height: 30vh;
  }
  .section-12a-bottom img:nth-child(2) {
    height: 30vh;
  }
}
