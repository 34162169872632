.third-carousel {
  position: absolute;
  bottom: 13%;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 1rem;
  text-align: center;
}

.third-single-slide {
  cursor: grab;
  width: 300px;
  min-height: 50px;
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.31);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.31);
}

.third-single-slide img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.third-single-slide-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.third-single-slide-profile h3 {
  font-size: 0.9rem;
}

.third-single-slide-profile p {
  font-size: 0.8rem;
}

.third-single-slide-review {
  font-size: 0.8rem;
}

@media all and (min-width: 380px) {
  .third-single-slide img {
    width: 50px;
    height: 50px;
  }
  .third-single-slide-profile h3 {
    font-size: 1rem;
  }

  .third-single-slide-profile p {
    font-size: 0.9rem;
  }

  .third-single-slide-review {
    font-size: 0.9rem;
  }
}

@media all and (min-width: 480px) {
  .third-single-slide-profile h3 {
    font-size: 1.3rem;
  }

  .third-single-slide-profile p {
    font-size: 1rem;
  }

  .third-single-slide-review {
    font-size: 1.1rem;
  }
}
